import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const PlanetTags = ({ asset, isBig = false }) => {
    const truncatedOwner = asset.owner.substring(0, Math.min(10, asset.owner.length))

    return (
        <>
            <span className={`has-text-left has-text-white ${isBig ? "is-size-5 is-size-6-mobile" : "is-size-7"}`} style={{ fontFamily: "PICO-8", position: "absolute", top: "10px", left: "15px" }}>
                #{asset.edition}<br />of<br />#100
            </span>

            {asset.rare && <span className={`tag is-link is-rounded ${isBig && "is-medium"}`} style={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "blue" }}>Rare</span>}
            {asset.epic && <span className={`tag is-link is-rounded ${isBig && "is-medium"}`} style={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "purple" }}>Epic</span>}
            {asset.legendary && <span className={`tag is-warning is-rounded ${isBig && "is-medium"}`} style={{ position: "absolute", top: "10px", right: "10px" }}>Legendary</span>}

            {asset.owner != "NullAddress" &&
                <span className={`tag is-light is-rounded ${isBig && "is-medium"}`} style={{ position: "absolute", bottom: "", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <span>Owned by</span>
                    <figure className="image is-16x16 mx-1">
                        <img className="is-rounded" style={{ borderRadius: "50%" }} src={asset.ownerAvatar} />
                    </figure>
                    <OutboundLink href={`https://opensea.io/accounts/${asset.owner}`} target="_blank" rel="noreferrer">{truncatedOwner}</OutboundLink>
                </span>
            }
        </>
    )
}

export default PlanetTags